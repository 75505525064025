<template>
  <div class="footer">
    <div class="wrap">
      <div class="upbody">
        <div class="l"><img src="../img/logo.png" class="footer_logo" /></div>
        <div class="r">
          <ul>
            <li class="item" v-for="(item, index) in hrefList" :key="index" @click="intoLocal(index)">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>


      <!-- <Nav :hrefList="hrefList" style="width:70%"></Nav> -->
      <p style="text-align: left; color: rgba(0, 0, 0, 0.7500); font-size: 14px; margin-top: 50px;">TABLINK SOLUTIONS PRIVATE LIMITED</p>
      <div class="footer_text">
        <p style="text-align: left;">AWFIS Bhutani Technopark, 7th Floor, Tower D, Plot No. 5, Sector 127, Noida - 201313</p>
        <p>© 2023 Thikana All Rights Reserved.</p>
      </div>
    </div>

  </div>
</template>

<script>
import Nav from '../Nav/Nav.vue'
export default {
  components: {
    Nav,
  },
  data() {
    return {
      // hrefList: ['About Us', 'Term Of Service', 'Privacy Policy',
      //   'Contact Us', 'Refund Policy', 'Cancellation Policy'],
      hrefList: ['About Us', 'Term Of Service', 'Privacy Policy',
        'Contact Us', 'Refund Policy']
    }
  },
  methods: {
    intoLocal(index) {
      console.log(index);
      if (index == 0) {
        this.$router.push({
          path: '/',
          query: {
            about: 1
          }
        })
        return
      }
      if (index == 1) {
        document.documentElement.scrollTop = 0
        this.$router.push('/service')
        return
      }
      if (index == 2) {
        document.documentElement.scrollTop = 0
        this.$router.push('/privacypolicy')
        return
      }
      if (index == 3) {
        this.$router.push({
          path: '/',
          query: {
            contact: 1
          }
        })
        return
      }
      if (index == 4) {
        document.documentElement.scrollTop = 0
        this.$router.push('/refundpolicy')
        return
      }
      if (index == 5) {
        document.documentElement.scrollTop = 0
        this.$router.push('/cancellationpolicy')
      }
    }
  }
}
</script>

<style lang="scss" scoped src="./footer.scss">

</style>
