<template>
  <div class="mobilefooter">
    <div class="footerlogo">
      <img src="../img/mobilelogo.png" alt="">
    </div>
    <div class="footerlink">
      <ul class="link_list">
        <li class="link_item" @click="gotoabout">About Us</li>
        <li class="link_item" @click="gotoview('mservice')">Term Of Service</li>
        <li class="link_item" @click="gotoview('mprivacypolicy')">Privacy Policy</li>
        <li class="link_item" @click="gotocontact">Contact Us</li>
        <li class="link_item" @click="gotoview('mrefundpolicy')">Refund Policy</li>
        <!-- <li class="link_item" @click="gotoview('mcancellationpolicy')">Cancellation Policy</li> -->
      </ul>
    </div>
    <div class="footertext">
      <p>TABLINK SOLUTIONS PRIVATE LIMITED</p>
      <p style="margin:.1rem 0 .36rem">AWFIS Bhutani Technopark, 7th Floor, Tower D, Plot No. 5, Sector 127, Noida - 201313</p>
      <p>© 2023 Thikana All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    gotoabout() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobileabout: 1
        }
      })
      return
    },
    gotocontact() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobilecontact: 1
        }
      })
      return
    },
    gotoview(path) {
      this.$router.push(`${path}`)
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped src="./mobilefooter.scss">

</style>
