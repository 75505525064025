<template>
  <div class="content">
    <div class="bg1">
      <div class="wrap">
        <div class="infous mg wow animate__fadeInUp" data-wow-duration="0.5s" data-wow-delay="0s" id="about">
          <div class="l">
            <p class="text_title" style="margin-bottom:16px">Who Are We?</p>
            <p class="text_sectitle" style="margin-bottom:40px">Thikana - The most popular voice-talking Platform . </p>
            <p class="text_content">Meeting new people has never been easier! Gather with friends without distance! You can enjoy yourself on the Thikana Platform.  </p>
            <p class="text_footer">TABLINK SOLUTIONS PRIVATE LIMITED</p>
          </div>
          <div class="r">
            <img src="../img/infousimg.png">
          </div>
        </div>
      </div>
    </div>
    <div class="bg2">
      <div class="wrap">
        <div class="infocan mg wow animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
          <div class="l">
            <img src="../img/infocanimg.png">
          </div>
          <div class="r">
            <p class="text_title">What can you do with Thikana? </p>
            <p style="margin:.16rem 0;font-size: 36px;font-family: Catamaran-Bold; color: rgba(0, 216, 219, 1);">ALL OF
              THESE!!</p>
            <p class="text_sectitle">PUBLIC VOICE ROOMS </p>
            <p class="text_content" style="margin:20px 0">
              Join or Make group voice calls with friends no matter where they are!
            </p>
            <p class="text_sectitle">PRIVATE CONVERSATIONS</p>
            <p class="text_content" style="margin:20px 0">
              Start private one-on-one text and voice conversations with your friends.</p>
            <p class="text_content" >
              Thikana wants everyone in our community to have fun!  Enjoy yourself and make friends.</p>
          </div>

        </div>
      </div>
    </div>
    <div class="bg3">
      <div class="wrap">
        <div class="chatnow mg wow animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
          <p class="text_title" style="margin-bottom:36px;text-align: center;">
            Let's Play Now
          </p>
          <div class="swp">
            <!-- :interval="1000000"  -->
            <el-carousel type="card" height="541px" class="paly_carousel">
              <el-carousel-item v-for="(item) in carImgList" :key="item">
                <img :src="item" class="medium" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="bg4">
      <div class="wrap">
        <div class="contectus mg wow animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0s" id="contact">
          <p class="text_title" style="text-align: center;">Contact Us</p>

          <div class="input_concent">
            <div class="input_item">
              <!-- <img src="../img/user_input.png" alt="" width="100%"> -->
              <input type="text" v-model="insertInfo.user" placeholder="Please enter name">
            </div>
            <div class="input_item">
              <!-- <img src="../img/email_input.png" alt="" width="100%"> -->
              <input type="text" v-model="insertInfo.email" placeholder="Please enter E-mail">
            </div>
            <div class="input_item">
              <!-- <img src="../img/phone_input.png" alt="" width="100%"> -->
              <input type="text" v-model="insertInfo.phone" placeholder="Please enter Phone">
            </div>
          </div>
          <textarea class="message_input" type="textarea" placeholder="Please enter Message"
            v-model="insertInfo.message"></textarea>
          <el-button class="send" :plain="true" @click="thro">Send</el-button>
          <div class="cusinfo">
            <p>
              <span>Customer care number: </span>
              <span class="cusinfo_value">+91 9220348815</span>
            </p>
            <p>
              <span>Customer care email ID: </span>
              <span class="cusinfo_value">contact@thikana.app</span>
            </p>
            <p style="margin-top:16px;">
              TABLINK SOLUTIONS PRIVATE LIMITED
            </p>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>


<script>
export default {
  watch: {
    "$route": {
      handler(val) {
        if (this.$route.query["contact"]) {
          this.toConcatUs()
        }
        if (this.$route.query["about"]) {
          this.toAboutus()
        }
      }
    }
  },
  data() {
    return {
      carImgList: [
        require('../img/car_img1.png'),
        require('../img/car_img2.png'),
        require('../img/car_img3.png')
      ],
      insertInfo: {
        user: '',
        email: '',
        phone: '',
        message: ''
      },
      timer: null,
      flag: false
    }
  },
  mounted() {

    if (this.$route.query["contact"]) {
      this.toConcatUs()
    }
    if (this.$route.query["about"]) {
      this.toAboutus()
    }
    new this.$wow.WOW({
      live: true
    }).init()

  },
  methods: {
    toConcatUs() {
      document.getElementById(`contact`).scrollIntoView()
      this.$router.push('/')
    },
    toAboutus() {
      document.getElementById(`about`).scrollIntoView()
      this.$router.push('/')
    },



    throttle(func, wait = 300, immediate = true) {
      if (immediate) {
        if (!this.flag) {
          this.flag = true;
          // 如果是立即执行，则在wait毫秒内开始时执行
          typeof func === 'function' && func();
          this.timer = setTimeout(() => {
            this.flag = false;
          }, wait);
        }
      } else {
        if (!flag) {
          this.flag = true
          // 如果是非立即执行，则在wait毫秒内的结束处执行
          this.timer = setTimeout(() => {
            this.flag = false
            typeof func === 'function' && func();
          }, wait);
        }

      }
    },
    thro() {
      this.throttle(this.SendMessage, 60000)
      // this.throttle(this.SendMessage, 1000)
    },

    SendMessage() {
      console.log('sendddd');
      this.insertInfo.user = ''
      this.insertInfo.email = ''
      this.insertInfo.phone = ''
      this.insertInfo.message = ''
      this.$message({
        message: 'send successfully',
        type: 'success'
        //type: warning
        // this.$message.error('shibai');
      })
    }

  }
}
</script>

<style lang="scss" scoped src="./content.scss">

</style>
